import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/layout/Index"
import Title from "../../components/commons/page/title/Index"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { withTrans } from "../../i18n/withTrans"

const Index = ({ data, t, i18n }) => {
  const language = i18n.language
  const dataFilter = data?.allWpVisiMisi?.nodes?.filter(o =>
    language === "id" ? o?.language?.code !== "EN" : o?.language?.code === "EN"
  )

  return (
    <Layout>
      <Container fluid className="page-container visi-misi">
        <Row className="title-container justify-content-between align-items-center mb-4">
          <Col md={12} className="page-title p-0">
            <span>{t("visionMission.visionMissionGakeslabIndonesia")}</span>
          </Col>
        </Row>
        {/* <Title label={t('visionMission.visionMissionGakeslabIndonesia')} /> */}
        <StaticImage
          src="../../../static/images/ornamen_1.png"
          alt="ornamen-1"
          className="ornamen-1"
          placeholder="blurred"
        />
        <Row className="visi-section">
          <Col md={6} className="visi-image">
            <StaticImage
              src="../../../static/images/gakeslab-visi.png"
              alt="visi"
              className="ltr-image"
              placeholder="blurred"
            />
            <StaticImage
              src="../../../static/images/ornamen_2.png"
              alt="ornamen-2"
              className="ornamen-2"
              placeholder="blurred"
            />
            <StaticImage
              src="../../../static/images/ornamen_1.png"
              alt="ornamen-1"
              className="ornamen-1"
              placeholder="blurred"
            />
            <StaticImage
              src="../../../static/images/ornamen_3.png"
              alt="ornamen-3"
              className="ornamen-3"
              placeholder="blurred"
            />
          </Col>
          <Col md={5} className="mini-visi">
            <div className="visi-desc">
              <StaticImage
                src="../../../static/images/ic_quote_green.png"
                alt="quote"
                className="quote-icon"
                placeholder="blurred"
              />
              <h3>{t("visionMission.ourVision")}</h3>
              <p>{dataFilter?.map(value => value?.visiMisi?.visi)}</p>
            </div>
          </Col>
        </Row>
        <Row className="misi-section">
          <Col md={{ span: 5, offset: 1 }}>
            <div className="misi-desc">
              <StaticImage
                src="../../../static/images/ic_quote_white.png"
                alt="quote"
                className="quote-icon"
                placeholder="blurred"
              />
              <h3>{t("visionMission.ourMission")}</h3>
              <p>{dataFilter?.map(value => value?.visiMisi?.misi)}</p>
            </div>
          </Col>
          <Col md={{ span: 5, offset: 1 }} className="misi-image">
            <StaticImage
              src="../../../static/images/gakeslab-misi.png"
              alt="misi"
              className="rtl-image"
              placeholder="blurred"
              layout="fullWidth"
            />
            <StaticImage
              src="../../../static/images/ornamen_2_white.png"
              alt="ornamen-2"
              className="ornamen-2 mini-hide"
              placeholder="blurred"
            />
            <StaticImage
              src="../../../static/images/ornamen_1_white.png"
              alt="ornamen-1"
              className="ornamen-1"
              placeholder="blurred"
            />
            <StaticImage
              src="../../../static/images/ornamen_3_white.png"
              alt="ornamen-3"
              className="ornamen-3"
              placeholder="blurred"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export default withTrans(Index)

export const pageQuery = graphql`
  query getVisiMisi {
    allWpVisiMisi {
      nodes {
        title
        language {
          code
        }
        visiMisi {
          misi
          visi
        }
      }
    }
  }
`
